import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useFormik } from 'formik';
import { Formik, Field } from 'formik';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import {
  useUserLoading,
  useUserInfo,
  useUserToken,
  useUserError,
  useUserSuccess,
  updateProfile,
  useAuthorized, resetUserSuccess, getProfile, getOrders, userSelector
} from '../../ducks/auth';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as ArrowLeft } from '../../svg/arrow-left-small.svg';
import {birthdayValid, getUserEditedPhone, getUserEmail} from '../../utils';
import { useTranslation } from "react-i18next";
import {Navigate, useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";
import InputBlock from "../common/input-block";
import InputBlockDate from "../common/input-block-date";
import InputBlockPhone from "../common/input-block-phone";
import InputBlockSelect from "../common/input-block-select";
import InputBlockGenderSelect from "../common/input-block-gender-select";

function EditAccountPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const isAuthorized = useAuthorized();

  const editAccountClassnames = classNames({
    'edit-address modal': true,
    'modal--visible': showModal
  });

  const headerClassnames = classNames({
    'modal-header': true,
    'modal-header--visible': showModal
  });

  const submitClassnames = classNames({
    'modal__submit modal__submit--centered': true,
    'modal__submit--visible': showModal
  });

  const userInfo = useUserInfo();
  const userLoading = useUserLoading();
  const userError = useUserError();
  const userSuccess = useUserSuccess();
  const userToken = useUserToken();
  const userEmail = getUserEmail(userInfo);
  const userPhone = getUserEditedPhone(userInfo);
  const userBirthdayValid = birthdayValid(userInfo);
  const dispatch = useDispatch();

  const handleSubmit = (profile) => {
    dispatch(updateProfile({ profile, userToken }));
    setTimeout(() => {
      dispatch(resetUserSuccess());
    }, 1000);
  }

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (!isAuthorized) {
      handleCloseModal();
    }
  }, [isAuthorized]);


  useEffect(() => {
    if (userSuccess) {
      handleBack();
    }
  }, [userSuccess]);

  useEffect(() => {
    if (isAuthorized && userToken && userInfo.firstname === undefined) {
      dispatch(getProfile({ token: userToken }));
    }
  }, [isAuthorized, userToken]);

  const validate = values => {
    const errors = {};

    if (!values.editFirstName) {
      errors.editFirstName = t('formValidation.required');
    }

    if (!values.editLastName) {
      errors.editLastName = t('formValidation.required');
    }

    if (!userBirthdayValid) {
      if (!values.editBirthday) {
        errors.editBirthday = t('formValidation.required');
      } else if (!/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/i.test(values.editBirthday)) {
        errors.editBirthday = t('formValidation.incorrectFormat');
      } else if (values.editBirthday.split('.')[0] > 31) {
        errors.editBirthday = t('formValidation.incorrectAddress');
      }
    }

    if (!values.editGender || values.editGender === '0') {
      errors.editGender = t('formValidation.required');
    }

    if (!values.editEmail) {
      errors.editEmail = t('formValidation.required');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.editEmail)) {
      errors.editEmail = t('formValidation.incorrectAddress');
    }

    if (!values.editPhone) {
      errors.editPhone = t('formValidation.required');
    } else if (values.editPhone.includes('_')) {
      errors.editPhone = t('formValidation.incorrectNumber');
    }

    return errors;
  }

  const formErrorClassnames = classNames({
    'form__error': true,
    'form__error--visible': userError
  });

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  const initialValues = {
    editFirstName: userInfo.firstname?? '',
    editLastName: userInfo.lastname?? '',
    editGender: userInfo.gender?? 0,
    editBirthday: userInfo.birthday?? '',
    editPhone: userPhone,
    editEmail: userEmail
  }

  return (
    <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={handleSubmit}
        validateOnMount
        enableReinitialize>
      {
        props => {
          const {touched, isValid, errors, values} = props;
          const submitDisabled = !isValid;

          return (
              <form className={editAccountClassnames} onSubmit={props.handleSubmit}>
                <div className={headerClassnames}>
                  <div className='modal-header__back'>
                    <button type='button' onClick={userLoading ? null : handleBack} className='base-header effect'>
                      <ArrowLeft/> {t('page.account.changeInfo')}
                    </button>
                  </div>
                  <div className='modal-header__mobile-close'>
                    <button className='effect' type='button' onClick={userLoading ? null : handleCloseModal}>
                      <CloseSmallIcon title='Закрыть'/>
                    </button>
                  </div>
                  <div className='modal-header__close'>
                    <button className='effect' type='button' onClick={userLoading ? null : handleCloseModal}>
                      <CloseIcon title='Закрыть'/> {t('page.close')}
                    </button>
                  </div>
                </div>
                <div className='modal__wrapper modal__wrapper--centered edit-account__form'>
                  {
                    !userLoading?
                        <div className='edit-account__inputs'>
                          <InputBlock
                              name='editFirstName'
                              label={`${t('page.auth.firstName')} *`}
                          />

                          <InputBlock
                              name='editLastName'
                              label={`${t('page.auth.lastName')} *`}
                          />

                          {/*<InputBlockSelect*/}
                          {/*    name='editGender'*/}
                          {/*    label={`${t('page.auth.gender')} *`}*/}
                          {/*    options={[*/}
                          {/*      { value: 0, name: t('page.auth.gender_not_selected') },*/}
                          {/*      { value: 1, name: t('page.auth.gender_male') },*/}
                          {/*      { value: 2, name: t('page.auth.gender_female') }*/}
                          {/*    ]}*/}
                          {/*/>*/}

                          <InputBlockGenderSelect
                              name='editGender'
                              label={`${t('page.auth.gender')} *`}
                              options={[
                                { value: 1, name: t('page.auth.gender_male') },
                                { value: 2, name: t('page.auth.gender_female') }
                              ]}
                          />

                          {
                            !userBirthdayValid?
                                <InputBlockDate
                                    name='editBirthday'
                                    label={`${t('page.auth.birthday')} *`}
                                /> : null
                          }

                          <InputBlockPhone
                              name='editPhone'
                              skipvalidation={1}
                              label={`${t('page.auth.phone')} *`}
                          />

                          <InputBlock
                              name='editEmail'
                              label={`${t('page.auth.email')} *`}
                          />

                        </div> : null
                  }

                  <div className={formErrorClassnames}>
                    {
                      userError ? <span>{t('common.sww')}</span> : null
                    }
                  </div>
                  <div className={submitClassnames}>
                    <button className='button effect2' type='submit' disabled={submitDisabled}>{t('page.apply')}</button>
                  </div>
                </div>
              </form>
          )
        }
      }
    </Formik>
  )
}

export default EditAccountPage;
