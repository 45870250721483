import React, { useEffect, useMemo, useState } from 'react';
import { YMaps, withYMaps } from 'react-yandex-maps';
import classNames from 'classnames';
import { useField, useFormikContext } from 'formik';
import { ReactComponent as Geo } from '../../svg/geo.svg';
import { cleanAddress } from '../../utils';
import { useTranslation } from "react-i18next";

function MapSuggestComponent(props) {
  const { t } = useTranslation();

  const { ymaps, onAddressChange, value } = props;
  const { setFieldValue, setFieldTouched, setFieldError } = useFormikContext();
  const [discovering, setDiscovering] = useState(false);
  const [suggest, setSuggest] = useState('');

  const geoLinkClasses = classNames({
    'address-input-geocoder-link': true,
    'address-input-geocoder-link-active': discovering
  });

  const onClickHandler = () => {
    if (discovering) { return }

    // setDiscovering(true);
    // ymaps.geolocation.get({ provider: 'auto', autoReverseGeocode: true })
    //   .then(result => {
    //     const discoveredAddress = cleanAddress(result.geoObjects.get(0).properties.get('text'));
    //
    //     setDiscovering(false);
    //     setSuggest(discoveredAddress);
    //     setFieldValue('address', discoveredAddress);
    //     props.onAddressChange(discoveredAddress);
    //   });
  };

  const validateAddress = (value) => {
    ymaps.geocode(value).then(function (res) {
      var obj = res.geoObjects.get(0), error, hint;

      if (obj) {
        switch (obj.properties.get('metaDataProperty.GeocoderMetaData.precision')) {
          case 'exact':
            break;
          case 'number':
          case 'near':
          case 'range':
            error = t('formValidation.notPrecisionAddress');
            hint = t('formValidation.specifyHouseNumber');
            break;
          case 'street':
            error = t('formValidation.notFullAddress');
            hint = t('formValidation.specifyHouseNumber');
            break;
          case 'other':
          default:
            error = t('formValidation.notPrecisionAddress');
            hint = t('formValidation.specifyAddress');
        }
      } else {
        error = t('formValidation.notPrecisionAddress');
        hint = t('formValidation.specifyAddress');
      }

      if (error) {
        setFieldError(props.name, error + ' ' + hint);
      } else {
        const handledValue = cleanAddress(value);
        setFieldValue(props.name, handledValue);
        onAddressChange(handledValue);
      }
    });
  }

  const onblurHandler = (e) => {
    setFieldTouched(props.name, true, true);
    if (!e.target.value) {
      return;
    }
    validateAddress(e.target.value);
  };

  useEffect(() => {
    const suggestView = new ymaps.SuggestView('suggest-address-input', {
      provider: {
        suggest: (request, options) => ymaps.suggest(`Москва, ${request}`)
      },
      results: 3
    });

    suggestView.events.add('select', (e) => {
      validateAddress(e.get('item').value);
    });
  }, [ymaps.SuggestView]);

  return (
    <div>
      <input
          id='suggest-address-input'
          defaultValue={value}
          onBlur={onblurHandler}
      />
      <Geo className={geoLinkClasses} onClick={onClickHandler}/>
    </div>
  );
}

function InputBlockAddress(props) {
  const [field, meta] = useField(props);
  const validationFailed = !!meta.touched && !!meta.error;

  const inputProps = Object.assign({}, props);
  delete inputProps.onAddressChange;

  const SuggestComponent = useMemo(() => {
    return withYMaps(MapSuggestComponent, true, [
      'SuggestView',
      'geocode',
      'suggest',
      'coordSystem.geo',
      'geolocation'
    ]);
  }, []);

  const inputClassnames = classNames({
    'input-block__input': true,
    'input-block__input--grey': true,
    'input-block__input--address': true,
    'input-block__input--error': validationFailed,
  });

  const errorClassnames = classNames({
    'input-block__error': true,
    'input-block__error--visible': validationFailed,
  });

  return (
    <div className='input-block'>
      <div className='input-block__label'>
        <label htmlFor={props.name}>{props.label}</label>
      </div>
      <div className={inputClassnames}>
        <YMaps query={{
          apikey: 'c0c23214-fb7f-4028-9a6e-f02a36475282',
          suggest_apikey: '181d31c5-3415-4b0c-a767-c50289e8de24' }}>
          <SuggestComponent
            onAddressChange={props.onAddressChange}
            {...field}
          />
        </YMaps>
        <input
          id={props.name}
          {...inputProps}
          {...field}
          className='input-block__hidden-input'
        />
      </div>
      <span className={errorClassnames}>{meta.error}</span>
    </div>
  )
}

export default InputBlockAddress;
