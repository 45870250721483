import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { ReactComponent as PickupIcon } from '../../svg/promo-pickup-icon.svg';
import { ReactComponent as PickupIconMobile } from '../../svg/promo-pickup-icon-mobile.svg';
import {toggleAboutPickupModal, orderSelector} from '../../ducks/order';
import { useTranslation } from "react-i18next";

function PromoBlockPickup() {
  const { t } = useTranslation();

  const [tapped, toggleTap] = useState(false);
  const dispatch = useDispatch();
  const orderData = useSelector(orderSelector);

  const thirdBlockClassnames = classNames({
    'promo__block': true,
    'promo__block--touched': tapped
  });

  const handleClick = () => {
    dispatch(toggleAboutPickupModal(true));
  }

  return (
    <div className={thirdBlockClassnames} onClick={handleClick}>
      <div className='promo__block-inner'>
        <div className='promo__block-first'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              <span className='hidden-mobile'>{t('promo.blockPickup.front_title0')}<br/></span>
              {t('promo.blockPickup.front_title1')}<br/>
              {t('promo.blockPickup.front_title2')}
            </div>
            <div className='promo__block-text-small mt-1'>
              {t('promo.blockPickup.back_subtitle0')}<br/>
              {t('promo.blockPickup.back_subtitle1')}<br/>
              {t('promo.blockPickup.back_subtitle2')}<br/>
              {t('promo.blockPickup.back_subtitle3')}
            </div>
          </div>
          <div className='promo__block-icon promo__block-icon-pickup'>
            <span className='hidden-mobile'><PickupIcon /></span>
            <span className='hidden-tablet'><PickupIconMobile /></span>
          </div>
        </div>
        <div className='promo__block-second-gray'>
          <div className='promo__block-second-header'>
            Maestrello pick up point
          </div>
          <div className='promo__block-second-text'>
            ваш помощник с бесконтактным<br/>
            заказом хрустящей римской<br/>
            пиццы, салатов, супов и десертов<br/>
            без очереди.
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlockPickup;
