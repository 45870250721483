import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { toggleSantaModal } from '../../ducks/santa';
import { useTranslation } from "react-i18next";

function PromoBlockSanta() {
  const { t } = useTranslation();

  const [tapped, toggleTap] = useState(false);
  const dispatch = useDispatch();

  const thirdBlockClassnames = classNames({
    'promo__block': true,
    'promo__block--touched': tapped
  });

  const handleClick = () => {
    dispatch(toggleSantaModal(true));
  }

  return (
    <div className={thirdBlockClassnames} onClick={handleClick}>
      <div className='promo__block-inner'>
        <div className='promo__block-santa'>
          <div className='promo__block-santa-bg'>
            <div className='promo__block-small-text'>
              <div className='promo__block-small-text--mobile'>{t('promo.blockSanta.first_title')}</div>
              <div className='promo__block-small-text--desktop'>{t('promo.blockSanta.first_title2')}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlockSanta;
