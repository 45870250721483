import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import {
  addDish,
  removeDish,
  cartSelector,
  priceSelector,
  cutlerySelector,
  increaseCutlery,
  decreaseCutlery,
  loadingSelector,
  increaseDish, decreaseDish
} from '../../ducks/cart';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as PlusIcon } from '../../svg/plus-black.svg';
import { ReactComponent as PlusSmallIcon } from '../../svg/plus-small.svg';
import { ReactComponent as MinusIcon } from '../../svg/minus-black.svg';
import { ReactComponent as EditIcon } from '../../svg/edit.svg';
import { ReactComponent as CheckMark } from '../../svg/check-mark.svg';
import { ReactComponent as EmptyCartIcon } from '../../svg/empty-cart.svg';
import { ReactComponent as CardBg } from '../../svg/card-bg.svg';
import { ReactComponent as CardBgSmall } from '../../svg/card-bg-small.svg';
import {
  additionalDishesSelector,
  desertGiftListSelector,
  dishGiftListSelector,
  pizzaGiftListSelector
} from '../../ducks/dishes';
import { checkDishInCart } from '../../utils';
import { elementScrollIntoView } from 'seamless-scroll-polyfill';
import { useTranslation } from "react-i18next";
import {useNavigate, useOutletContext} from "react-router";
import { Link, useLocation } from "react-router-dom";
import ExtraDishBlock from "./extra-dish-block";
import FriendGiftDishBlock from "./gift-dish-block";
import {getOrders, getProfile, useAuthorized, useUserInfo, useUserToken} from "../../ducks/auth";
import GiftDishBlock from "./gift-dish-block";
import ValentineDishBlock from "./valentine-dish-block";

function getAdditionsString(additions, lang) {

  let string = '';
  additions.forEach((t, i) => {
    if (i + 1 === additions.length) {
      string += lang === 'en' ? `${t.additionNameEn}` : `${t.additionName}`
    } else {
      string += lang === 'en' ? `${t.additionNameEn}, ` : `${t.additionName}, `
    }
  })
  return string.toLowerCase();
}

function getAdditionsPrice(additions) {
  let price = 0;
  additions.forEach((t, i) => {
    price += t.additionPrice;
  })
  return price;
}

function CartPage({ selectDishFunc }) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const cartItems = useSelector(cartSelector);
  const cartIsEmpty = !cartItems.length;
  const totalPrice = useSelector(priceSelector);
  const giftEnabled = totalPrice >= 1500;
  const cutlery = useSelector(cutlerySelector);
  const loading = useSelector(loadingSelector);

  /* gifts */
  const isAuthorized = useAuthorized();
  const userToken = useUserToken();
  const userInfo = useUserInfo();
  const gifts = userInfo? userInfo.locards_level_gifts : null;
  const [showRefererGift, setShowRefererGift] = useState(false);
  const [showDesertGift, setShowDesertGift] = useState(false);
  const [showPizzaGift, setShowPizzaGift] = useState(false);
  const giftDeserts = useSelector(desertGiftListSelector);
  const giftPizza = useSelector(pizzaGiftListSelector);

  const cartClassnames = classNames({
    'cart modal modal--no-padding': true,
    'modal--visible': showModal
  });

  const submitClassnames = classNames({
    'modal__submit': true,
    'modal__submit--visible': showModal,
    'modal__submit--empty-cart': cartIsEmpty
  });


  const handleIncrease = (idx) => {
    dispatch(increaseDish(idx));
  };

  const handleDecrease = (idx) => {
    dispatch(decreaseDish(idx));
  };

  const handleIncreaseCutlery = () => {
    dispatch(increaseCutlery());
  };

  const handleDecreaseCutlery = () => {
    dispatch(decreaseCutlery());
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  const handleBack = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate(-1);
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }
  }, [hideChild]);

  useEffect(() => {
    if (isAuthorized && userToken) {
      dispatch(getProfile({ token: userToken }));
      dispatch(getOrders({ token: userToken }));
    }
  }, [isAuthorized, userToken]);

  useEffect(() => {
    if (userInfo) {
      if (userInfo.referer_gift !== undefined) {
        setShowRefererGift(userInfo.referer_gift);
      } else {
        setShowRefererGift(false);
      }
    }
  }, [userInfo]);

  useEffect(() => {
    if (gifts) {
      const { desert, pizza } = gifts;
      if (desert.count && desert.min_order <= totalPrice) {
        setShowDesertGift(true);
      } else {
        setShowDesertGift(false);
      }

      if (pizza.count && pizza.min_order <= totalPrice) {
        setShowPizzaGift(true);
      } else {
        setShowPizzaGift(false);
      }
    }
  }, [gifts, cartItems]);

  const giftClass = classNames({
    'cart__gift': true,
    'cart__gift--active': giftEnabled
  });

  const handleClickMenu = event => {
    event.preventDefault();
    navigate('/');
    elementScrollIntoView(document.getElementById('#pizza'), { behavior: 'smooth', block: 'top'  });
  };

  return (
      <div className={cartClassnames}>
        <div className='modal__wrapper'>
          <div className='cart__col--button'>
            <button className='effect' onClick={handleCloseModal}>
              <CloseIcon title='Закрыть' />
            </button>
          </div>
          <div className='cart__col--button-tablet'>
            <button className='effect' onClick={handleCloseModal}>
              <CloseSmallIcon title='Закрыть' />
            </button>
          </div>

          {
            cartIsEmpty ?
                <div className='cart__col'>
                  <div className='cart__col-item-full-tablet'>
                    <div className='cart__col-item cart__col-item-full'>
                      <div className='cart__col-item--header'>
                        <span className='cart__col--text-header'>{t('page.cart.link')}</span>
                        <div className='cart__col--button-mobile'>
                          <button className='effect' onClick={handleCloseModal}>
                            <CloseSmallIcon title='Закрыть'/>
                          </button>
                        </div>
                      </div>

                      <div className='cart__col-item--center'>
                        <div className='cart__empty-icon'>
                          <EmptyCartIcon title='В корзине пусто :('/>
                        </div>
                        <div className='cart__empty base-header'>
                          {t('page.cart.empty1')} <br/>
                          {t('page.cart.empty2')}
                        </div>
                      </div>

                      <div className={submitClassnames}>
                        <a onClick={handleClickMenu} href='/' className='cart-link effect2'>
                          {t('page.cart.shippingMenu')}
                        </a>
                      </div>
                    </div>
                  </div>
                </div> :
                <>
                  <div className='cart__row-tablet'>
                    <div className='cart__col'>
                      <div className='cart__col-item cart__col-item-full'>
                        <div className='cart__col-item--header'>
                          <span className='cart__col--text-header'>{t('page.cart.link')}</span>
                          <div className='cart__col--button-mobile'>
                            <button className='effect' onClick={handleCloseModal}>
                              <CloseSmallIcon title='Закрыть'/>
                            </button>
                          </div>
                        </div>

                        {
                          cartItems.map((dish, i) => {
                            // console.log(dish);
                            const {id, amount, additions, name, name_en, price, toppings, category_id} = dish;
                            const img = `https://pizzamaestrello.com/img/menu/product_${id}.png`;
                            let additionsBlock = null;
                            let additionsPrice = 0;

                            const isGiftDish = category_id === '10' || category_id === '11';
                            const altImageLayout = category_id === '1' ||
                                category_id === '2' ||
                                category_id === '6' ||
                                category_id === '11';

                            const itemImageClassnames = classNames({
                              'cart__item-img': !altImageLayout,
                              'cart__item-img-alt': altImageLayout,
                            });

                            if (additions && additions.length) {
                              additionsPrice = getAdditionsPrice(additions);
                              additionsBlock =
                                  <div className='cart__ingridients cart__ingridients--added'>
                                    <span onClick={() => selectDishFunc(cartItems[i], i)}
                                          className='cart__edit-btn effect3 no-flick'>
                                      <span className='cart__edit-icon'><EditIcon/></span>
                                      {t('page.cart.additional')} {getAdditionsString(additions, i18n.language)}
                                    </span>
                                  </div>
                            } else if (toppings) {
                              additionsBlock =
                                  <div className='cart__ingridients'>
                                    <span
                                        onClick={() => selectDishFunc(cartItems[i], i)}
                                        className='cart__edit-btn effect3 no-flick'>
                                      <span className='cart__edit-icon'>
                                        <PlusSmallIcon/>
                                      </span>
                                      {t('page.cart.addIngredients')}
                                    </span>
                                  </div>
                            }

                            return (
                                <div key={i} className='cart__item'>
                                  <div className='cart__top'>
                                    <div className={itemImageClassnames}>
                                      <img src={img} width='170'
                                           alt={`${name} / ${name_en}`}/>
                                    </div>
                                    <div className='cart__info'>
                                      <div className='cart__name'>
                                      <span className='cart__name-en'>
                                        {
                                          i18n.language === 'en' ? name_en : name
                                        }
                                      </span>
                                      </div>
                                      <div className='cart__price-block'>
                                        <span>
                                          {
                                              price + additionsPrice > 0?
                                                  `${price + additionsPrice} ₽` : t('page.cart.free')
                                          }
                                        </span>
                                        {
                                          (price + additionsPrice > 0) && !isGiftDish?
                                              <div className='cart__amount-block'>
                                                <button onClick={() => handleDecrease(i)}
                                                        className='cart__amount-btn effect no-flick'>
                                                  <MinusIcon title='Удалить'/>
                                                </button>
                                                <span className='cart__amount-value'>
                                                  {amount}
                                                </span>
                                                <button onClick={() => handleIncrease(i)}
                                                        className='cart__amount-btn effect no-flick'>
                                                  <PlusIcon title='Добавить'/>
                                                </button>
                                              </div>
                                              : null
                                        }
                                      </div>
                                    </div>
                                  </div>
                                  {additionsBlock}
                                </div>
                            )
                          })
                        }
                        {
                          !giftEnabled ? null :
                              <div key='gift' className='cart__item'>
                                <div className='cart__top'>
                                  <div className='cart__item-img-alt'>
                                    <img className='cart__content-img'
                                         src='https://pizzamaestrello.com/img/menu/product_8.png' width='170'
                                         alt={`Маргарита / Margherita`}/>
                                  </div>
                                  <div className='cart__info'>
                                    <div className='cart__name'>
                                      <span className='cart__name-en'>
                                      {
                                        i18n.language === 'en' ? 'Margherita' : 'Маргарита'
                                      }
                                      </span>
                                    </div>
                                    <div className='cart__price-block'>
                                      <div>
                                        {t('page.cart.free')}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                        }

                        <div className='cart__cutlery'>
                          <span className='cart__cutlery-text'>{t('page.cart.numberOfCutlery')}</span>
                          <div className='cart__amount-block'>
                            <button onClick={() => handleDecreaseCutlery()} className='cart__amount-btn effect no-flick'>
                              <MinusIcon title='Удалить'/>
                            </button>
                            <span className='cart__amount-value'>
                              {cutlery}
                            </span>
                            <button onClick={() => handleIncreaseCutlery()} className='cart__amount-btn effect no-flick'>
                              <PlusIcon title='Добавить'/>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='cart__col'>
                      {/*<ValentineDishBlock/>*/}

                      {/* free pizza */}
                      <div className='cart__col cart__col-item-full-tablet'>
                        <div className='cart__col-item cart__col-item-full'>
                          <div className={giftClass}>
                            <div className='cart__gift-left'>
                              <div className='cart__gift-header base-header'>
                                {
                                  giftEnabled ? t('page.cart.giftInBasket') : t('page.cart.pizzaAsGift')
                                }
                              </div>
                              <div className='cart__gift-text base-text'>
                                {
                                  giftEnabled ? t('page.cart.miniAdded') : t('page.cart.miniToAdd', {price: 1500 - totalPrice})
                                }
                              </div>
                            </div>
                            <div className='cart__gift-right'>
                              <img src='https://pizzamaestrello.com/img/menu/product_8.png' alt='Подарок'/>
                            </div>
                          </div>
                        </div>
                      </div>

                      <ExtraDishBlock/>
                      {
                          showRefererGift? <GiftDishBlock
                              title={t('page.cart.desertGiftFriend')}
                              show={showRefererGift}
                              dishList={ giftDeserts }
                              maxItems={1} /> : null
                      }
                      {
                          showDesertGift? <GiftDishBlock
                              title={t('page.cart.desertGiftLevelChange')}
                              show={showDesertGift}
                              dishList={ giftDeserts }
                              maxItems={1} /> : null
                      }
                      {
                          showPizzaGift? <GiftDishBlock
                              title={t('page.cart.pizzaGiftLevelChange')}
                              show={showPizzaGift}
                              dishList={ giftPizza }
                              maxItems={1} /> : null
                      }
                    </div>
                  </div>

                  {/* free desert */}
                </>
          }

          {
            cartIsEmpty ? null :
                <div className={submitClassnames}>
                  {
                    loading ?
                        <button className='button button--loading'>
                          <span className='loader'>{t('common.loading')}</span>
                        </button> :
                        <Link to='/order' className='button button--multiple-children effect2'>
                          <span>{t('page.cart.checkout')}</span> <span className='base-text-white'>{totalPrice} ₽</span>
                        </Link>
                    // <button className='button button--multiple-children effect2' onClick={openOrderFunc}>
                    //   <span>{t('page.cart.checkout')}</span> <span className='base-text-white'>{totalPrice} ₽</span>
                    // </button>
                  }
                </div>
          }
        </div>
      </div>
  )
}

export default CartPage;
