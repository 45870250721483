// import firebase from 'firebase/app';
// import { firebaseConfig } from '../config';
import axios from 'axios';

class ApiService {
  // constructor(firebaseConfig) {
  //   this.fb = firebase.initializeApp(firebaseConfig);
  // }

  getDishes = () => axios.get('https://pizzamaestrello.com/api.php/p/v1/products?offset=0&hash=search/status=1')

  getToppings = (id) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/product-type-services?id=${id}`)

  doLogin = ({ email, password }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/auth?login=${email}&password=${password}`)

  doRegister = (dataToRegister) => axios.post(`https://pizzamaestrello.com/api.php/p/v2/register`, dataToRegister)

  doJoinUser = ({ userId, locale }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/join?id=${userId}&locale=${locale}`)

  doRestorePwd = ({ login, locale }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/recover-password?login=${login}&locale=${locale}`)

  doResetPwdHash = (data) => axios.post(`https://pizzamaestrello.com/api.php/p/v1/reset-password`, data)

  getProfile = ({ token, locale }) => axios.get(`https://pizzamaestrello.com/api.php/p/v2/get-profile?access_token=${token}&locale=${locale}`)

  updateProfile = ({ token, newUserData }) => axios.post(`https://pizzamaestrello.com/api.php/p/v2/update-profile?access_token=${token}`, newUserData)

  addAddress = ({ token, newAddress }) => axios.post(`https://pizzamaestrello.com/api.php/p/v1/add-address?access_token=${token}`, newAddress)

  deleteAddress = ({ token, addressId }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/delete-address?access_token=${token}&id=${addressId}`)

  getOrders = ({ token }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/get-orders?access_token=${token}&limit=3`)

  getAllOrders = ({ token }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/get-profile?access_token=${token}`)

  getDeliveryCost = (data) => axios.post(`https://pizzamaestrello.com/api.php/p/v1/calc-shipping`, data)

  createOrder = (data) => axios.post(`https://pizzamaestrello.com/api.php/p/v2/create-order`, data)

  createOrderReg = (data, token) => axios.post(`https://pizzamaestrello.com/api.php/p/v2/create-order?access_token=${token}`, data)

  createPayment = ({ orderId }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/pay-order?id=${orderId}`)

  getOrderStatus = ({ orderId, locale }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/get-order-status?id=${orderId}&locale=${locale}`)

  sendPhoneCode = ({ phone, locale, trace }) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/send-phone-code?phone=${phone}&locale=${locale}&trace=${trace}&robot=2000`)

  checkPhoneCode = (data) => axios.post('https://pizzamaestrello.com/api.php/p/v1/validate-phone-code', data)

  checkPromo = (data) => axios.post('https://pizzamaestrello.com/api.php/p/v2/calc-discounts', data)

  checkPromoReg = (data, token) => axios.post(`https://pizzamaestrello.com/api.php/p/v2/calc-discounts?access_token=${token}`, data)

  getDeliveryPolygons = () => axios.get('https://pizzamaestrello.com/api.php/p/v1/get-delivery-polygons')

  getDeliveryTime = (coords) => axios.post('https://pizzamaestrello.com/api.php/p/v1/calc-shipping-time', coords)

  getSantaSales = () => axios.get('https://pizzamaestrello.com/api.php/p/v1/get-santa-sales')

  getPaymentStatus = (hash) => axios.get(`https://pizzamaestrello.com/api.php/p/v1/get-order-by-hash?hash=${hash}`)

}

// export default new ApiService(firebaseConfig);
export default new ApiService();
