import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { ReactComponent as ValentineIcon } from '../../svg/promo-valentine.svg';
import { ReactComponent as ValentineLogo } from '../../svg/promo-valentine-logo.svg';

import amplitude from 'amplitude-js';
import { useTranslation } from "react-i18next";

function PromoBlockValentine() {
  const { t } = useTranslation();

  const [tapped, toggleTap] = useState(false);

  const firstBlockClassnames = classNames({
    'promo__block': true,
    // 'promo__block--hidden-on-desktop': isAuthorized,
    'promo__block--touched': tapped
  });

  useEffect(() => {
    if (tapped) {
      amplitude.getInstance().logEvent('buttom_main_special_offer', {
        offer_name: 'sale_delivery'
      });
    }
  }, [tapped]);

  return (
    <div className={firstBlockClassnames} onClick={() => toggleTap(!tapped)}>
      <div className='promo__block-inner'>
        <div className='promo__block-first promo__block-valentine'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              {t('promo.blockValentine.front_title0')}<br/>
              {t('promo.blockValentine.front_title1')}
            </div>
          </div>
          <div className='promo__block-icon-valentine'>
            <ValentineIcon />
          </div>
          <div className='promo__block-icon-valentine-logo'>
            <ValentineLogo />
          </div>
        </div>
        <div className='promo__block-second promo__block-valentine'>
          <div className='promo__block-text-wrapper'>
            <div className='promo__block-text'>
              {t('promo.blockValentine.back_title')}
            </div>
            <div className='promo__block-text-small mt-1'>
              <p>
                {t('promo.blockValentine.back_subtitle0')}
              </p>
              <p>
                {t('promo.blockValentine.back_subtitle1')}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PromoBlockValentine;
