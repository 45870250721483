import React, {useEffect, useState} from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import {
  useAuthorized,
  useUserInfo,
  useUserToken,
  logout,
  getProfile,
  getOrders
} from '../../ducks/auth';
import { ReactComponent as CloseSmallIcon } from '../../svg/close-36.svg';
import { ReactComponent as CloseIcon } from '../../svg/close-48.svg';
import { ReactComponent as EditIcon } from '../../svg/edit-v2.svg';
import { ReactComponent as PlusIcon } from '../../svg/plus-white.svg';
import { ReactComponent as PlusIconBlack } from '../../svg/plus-black.svg';
import {getUserEmail, getUserPhone, getUserAddresses, getLoCardsLevel} from '../../utils';
import { useTranslation } from "react-i18next";
import {Navigate, useNavigate, useOutletContext} from "react-router";
import {useLocation} from "react-router-dom";
import Dropdown from "./dropdown";
import AccountOfferBlockFreePoints from "./account-offer-block-free-points";
import AccountOrdersBlock from "./account-orders-block";
import AccountLevelsBlock from "./account-levels-block";
import QRCode from "react-qr-code";

function AccountPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const [hideChild, setHideChild]= useOutletContext();
  const [showModal, setShowModal] = useState(false);

  const dispatch = useDispatch();
  const isAuthorized = useAuthorized();
  const userInfo = useUserInfo();
  const userToken = useUserToken();
  const userName = userInfo ? userInfo.firstname : '';
  let userEmail = getUserEmail(userInfo);
  const userPhone = getUserPhone(userInfo);
  const userAddresses = getUserAddresses(userInfo);

  if (userEmail.length > 15) {
    userEmail = userEmail.substring(0, 14) + '...';
  }

  /* PrimeBonus integration */
  const loyaltyCard = userInfo ? userInfo.loyalty_card : false;
  const loyaltyCardUrl = userInfo ? userInfo.loyalty_card_url : '';
  const loyaltyLevel = userInfo ? parseInt(userInfo.loyalty_level) : -1;
  const loyaltyBalance = userInfo ? userInfo.loyalty_balance > 0 ? userInfo.loyalty_balance.toFixed(0) : userInfo.loyalty_balance : '';
  const loyaltyToNextAmount = userInfo ? userInfo.loyalty_to_next_amount > 0 ? userInfo.loyalty_to_next_amount.toFixed(0) : userInfo.loyalty_to_next_amount : '';
  const { loyaltyLevelName, loyaltyNextLevel } = getLoCardsLevel(userInfo, i18n.language);

  /* Form animation */
  const accountClassnames = classNames({
    'account modal modal--no-padding': true,
    'modal--visible': showModal
  });

  /* QRCode */
  const [showQRCode, setShowQRCode] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/');
    }, 200)
  }

  const handleEdit = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('edit');
    }, 200)
  }

  const addAddress = () => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('add-address');
    }, 200)
  }

  const handleCardLink = () => {
    window.location.href = loyaltyCardUrl;
  }

  const handleCardLinkQr = () => {
    if (showQRCode) {
      setShowQRCode(false);
    } else {
      setShowQRCode(true);
      /* Auto close QR Code */
      // setTimeout(() => {
      //   setShowQRCode(false);
      // }, 30000);
    }
  }

  const handleEditAddress = (idx) => {
    setShowModal(false);
    setTimeout(()=> {
      navigate('/account/edit-address/' + idx);
    }, 200)
  }

  useEffect(() => {
    if (location) {
      setTimeout(()=> {
        setShowModal(true);
      }, 200)
    }
  }, [loyaltyBalance, location]);

  useEffect(() => {
    if (hideChild) {
      handleCloseModal();
      setHideChild(false);
    }

  }, [hideChild]);

  useEffect(() => {
    if (isAuthorized && userToken) {
      dispatch(getProfile({ token: userToken, locale: i18n.language }));
      dispatch(getOrders({ token: userToken, locale: i18n.language }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthorized, userToken, i18n.language]);

  const doLogout = () => {
    dispatch(logout());
  }

  const loyaltyLevelClassnames = classNames({
    'base-header': true,
    'account__loyalty': true,
    'account__loyalty--hidden': !loyaltyCard
  });

  const addToWalletClassnames = classNames({
    'account__info--negative-desktop base-text': true,
    'effect no-flick': true,
    'account__info--negative-desktop-active': showQRCode
  });

  const levelInfoClassnames = classNames({
    'invisible': !loyaltyLevel,
  });

  if (!isAuthorized) {
    return <Navigate to="/login" replace />;
  }

  return (
      <div className={accountClassnames}>
        <div className='modal__wrapper'>
          <div className='account__col--button'>
            <button className='effect' onClick={handleCloseModal}>
              <CloseIcon title='Закрыть' />
            </button>
          </div>
          <div className='account__col--button-tablet'>
            <button className='effect' onClick={handleCloseModal}>
              <CloseSmallIcon title={t('common.close')} />
            </button>
          </div>

          <div className='account__col'>
            <div className='account__col-white-header-bg-tablet'>
              <div className='account__col-item-one-third-tablet'>

                <div className='account__col-item account__col-item-full'>
                  <span className='account__col--text-header'>{userName}</span>
                  <span className={loyaltyLevelClassnames}>{loyaltyLevelName}</span>
                  <div className='account__col--button-mobile'>
                    <button className='effect' onClick={handleCloseModal}>
                      <CloseSmallIcon title={t('common.close')} />
                    </button>
                  </div>
                </div>

                <div className='account__col-item account__col-item-full mt-1 account__info-wrapper'>
                  <div className='account__info base-text'>
                    <div>{userPhone}</div>
                    <div>{userEmail}</div>
                    <div className='account__edit-info'>
                    <span onClick={ handleEdit } className='account__edit-info-btn effect3'>
                      <span className='account__edit-info-icon'><EditIcon /></span>
                    </span>
                    </div>
                  </div>
                  <div onClick={ handleCardLink } className='account__info--negative base-text effect no-flick'>
                    <span>{t('page.account.addCard')}</span> <span>{t('page.account.toWallet')} <PlusIcon></PlusIcon></span>
                  </div>
                  <div onClick={ handleCardLinkQr } className={addToWalletClassnames}>
                    <span>{t('page.account.addCard')} {t('page.account.toWallet')}</span> <PlusIcon></PlusIcon>
                    <div className='account__info--negative-qr-code'>
                      <span>QR-код</span>
                      {
                        loyaltyCardUrl? <QRCode value={loyaltyCardUrl}></QRCode> : null
                      }
                      <span>{t('page.account.addToWallet')}</span>
                    </div>
                    {/*{*/}
                    {/*  !showQRCode?*/}
                    {/*      <><span>Добавить карту в Wallet</span> <PlusIcon></PlusIcon></> :*/}
                    {/*      <><span>QR-код</span><QRCode value={locardsCardUrl}></QRCode><span>Добавить в Wallet</span></>*/}
                    {/*}*/}
                  </div>
                </div>
              </div>

              <div className='account__col-item-auto-tablet'>
                <div className='account__col-item account__col-item-full mt-2-mobile is-column is-left-aligned'>
                  <span className='account__col--text-header'>{t('page.account.points')}</span>
                  <div className='account__col--points-wrapper mt-1'>
                    {
                      loyaltyCard? <span>{loyaltyBalance}</span> : <span className='base-text account__col--points-dimmed'>{t('page.account.fillProfileFields')}</span>
                    }
                    <span className={levelInfoClassnames}>
                      {
                        loyaltyLevel < 3? <>{loyaltyToNextAmount} ₽<br/> до {loyaltyNextLevel}<br/></> : null
                      }
                      {
                        loyaltyLevel? <>1 {t('page.account.point')} = 1 ₽</> : null
                      }
                    </span>
                  </div>
                </div>
              </div>

              <div className='account__col-item-one-third-tablet'>
                <div className='account__col-item account__col-item-full mt-2-mobile is-column is-left-aligned'>
                  <span className='account__col--text-header'>{t('page.account.addresses')}</span>
                  <div className='account__col--address-wrapper mt-1'>
                    <Dropdown options={ userAddresses } onClickItem={ handleEditAddress } />
                    <div className='effect3 account__button-link mt-1' onClick={ addAddress }>
                      {t('page.account.addAddress')}
                      <PlusIconBlack/>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <AccountOfferBlockFreePoints/>

          <div className='account__col mt-2'>
            <AccountLevelsBlock/>
          </div>

          <div className='account__col mt-2'>
            <AccountOrdersBlock/>
          </div>

          <div className='account__left mt-2'>
            <div className='account__link'>
                <a href='/loyalty.pdf' className='base-text effect' target='_blank'>{t('page.account.rulesLevels')}</a>
            </div>
          </div>

          <div className='account__right mt-2'>
            <div className='account__link'>
               <span onClick={doLogout} className='base-text effect'>{t('page.account.logOut')}</span>
            </div>
          </div>

        </div>
      </div>
  );
}

export default AccountPage;
